// Create sidebar component
import React from 'react';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import { Landmark, Settings, Users, LogOut, ChevronDown } from 'lucide-react'
import { useAuth } from '../../context/authContext';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { getUserDetails } from '../../services/user.service';

const Sidebar = () => {

    const { user, setUser, setAuthenticated } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const links = [
        {
            id: 1,
            text: 'Payments',
            url: '/payments',
            icon: <Landmark />
        },
        {
            id: 2,
            text: 'Settings',
            url: '/settings',
            icon: <Settings />
        },
    ];

    const adminLinks = [
        {
            id: 3,
            text: 'User Management',
            url: '/users',
            icon: <Users />
        },
    ];

    async function handleChangeOrganisation(organisation) {
        const userDetails = await getUserDetails(user.token, organisation.id, user.id);
        setUser({ ...user, ...userDetails.data.data, organisationId: organisation.id, organisationName: organisation.name });
        if (location.pathname.includes('/payments')) {
            navigate('/payments');
        }
    }
    
    return (
        <aside className="bg-gradient-to-b from-yavrio-blue-dark to-yavrio-blue-darker w-72 p-6 text-white flex flex-col">
            <div className="">
                
                <div className='flex flex-row justify-between items-start mt-4'>
                    <h3 className='font-semibold text-white text-left my-0'>{user.organisationName}</h3>

                    { user.organisations.length > 1 && (
                        <DropdownMenu>
                            <DropdownMenuTrigger><ChevronDown /></DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuLabel>Select Organisation</DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                { user.organisations.map((organisation) => (
                                    <DropdownMenuItem 
                                        key={organisation.id} 
                                        value={organisation.id}
                                        onClick={() => handleChangeOrganisation(organisation)}
                                    >
                                        {organisation.name}
                                    </DropdownMenuItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>

                <hr className='opacity-20 my-10 w-4/5'/>
                <ul className="flex flex-col gap-6 mt-4">
                {links.map((link) => {
                        const { id, text, url, icon } = link;
                        return (
                            <li key={id}>
                                <Link to={url}>
                                    <div className='flex flex-row gap-3'>
                                        {icon}
                                        {text}
                                    </div>
                                </Link>
                            </li>
                        );
                    })}
                {user.role === 'Admin' && adminLinks.map((link) => {
                    const { id, text, url, icon } = link;
                    return (
                        <li key={id}>
                            <Link to={url}>
                                <div className='flex flex-row gap-3'>
                                    {icon}
                                    {text}
                                </div>
                            </Link>
                        </li>
                    );
                })}
                <Link to="#"
                onClick={() => {
                      setUser({
                            email: '',
                            role: '',
                            isActive: false,
                            twoFactorEnabled: false,
                        });
                      setAuthenticated(false);
                  }}
                >
                    <div className='flex flex-row gap-3'>
                        <LogOut />
                        Logout
                    </div>
                </Link>
                </ul>
            </div>
            <div className='mt-auto text-white'>
                <Link to='mailto:support@yavr.io' className=''>Need help?</Link>
                <p className='text-white mt-10'>Yavrio Ltd. © 2024</p>
            </div>
        </aside>
    );
};

export default Sidebar;